
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonInput, IonButton, modalController } from "@ionic/vue";
import { ref, onMounted, nextTick } from "vue";
import { useRouter } from "vue-router";

// Leaflet main component
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";

// Sovrascrive le immagini di default per i marker
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
// Cluster
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";

// Services
import { openToast } from "@/services/toast";
import apiClienti from "@/services/clienti";

import ClienteDetail from "./ClienteDetail.vue";

export default {
    name: "Tab4",
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonInput,
        IonButton,
    },
    setup() {
        const user = JSON.parse(localStorage.getItem("userInfo"));
        const router = useRouter();
        const localMode = JSON.parse(localStorage.getItem("localMode"));

        const clienti = ref([]);

        /**
         * Apre dettaglio cliente
         */
        async function openDetailModal(customer) {
            //console.log(customer);
            const modal = await modalController.create({
                component: ClienteDetail,
                componentProps: {
                    data: customer,
                },
            });
            return modal.present();
        }

        // Simula il ridimensionamento della finestra del browser
        function simulateResize() {
            const event = new Event("resize");
            window.dispatchEvent(event);
        }

        const map = ref(null);

        const markers = ref(L.markerClusterGroup());

        async function setMap() {
            //Rimuove tutti i livelli (base e cluster)
            markers.value.clearLayers();

            // Inizializza mappa per la prima volta
            if (!map.value) {
                map.value = L.map("mapid", {
                    zoomControl: true,
                    zoom: 1,
                    zoomAnimation: false,
                    fadeAnimation: true,
                    markerZoomAnimation: true,
                    attributionControl: false,
                }).setView([45.955837, 12.979869], 13);

                L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                    maxZoom: 19,
                }).addTo(map.value);
            } else {
                /*  if (markers.value && map.value.hasLayer(markers.value)) {
                    map.value.removeLayer(markers.value);
                } */

                // Setta solo layer base
                L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                    maxZoom: 19,
                }).addTo(map.value);
            }

            // Add marker for each customer
            clienti.value.forEach((cliente, index) => {
                //console.log(cliente.customers_position);

                if (cliente.customers_position) {
                    const coordinatesArray = cliente.customers_position.split(";");
                    const latitude = parseFloat(coordinatesArray[0]);
                    const longitude = parseFloat(coordinatesArray[1]);

                    if (!isNaN(latitude) && !isNaN(longitude)) {
                        const single_marker = L.marker([latitude, longitude]);
                        single_marker.on("click", () => {
                            // Rimandare al dettaglio cliente tramite router
                            single_marker.bindPopup(`${cliente.customers_name} ${cliente.customers_last_name}`);
                            openDetailModal(cliente);
                        });

                        // Aggiunge l'evento per l'apertura del popup
                        /* single_marker.on("popupclose", () => {
                            // Mostra un alert quando il popup del marker viene aperto
                            alert(`${cliente.customers_name} ${cliente.customers_last_name}`);
                        }); */

                        // Aggiunge l'evento per l'apertura del popup
                        /* single_marker.on("popupopen", () => {
                            // Mostra un alert quando il popup del marker viene aperto
                            const popup = single_marker.getPopup();
                            if (popup) {
                                popup.getElement().addEventListener("click", () => {
                                    // Mostra un alert quando viene cliccato il popup
                                    alert(`${cliente.customers_name} ${cliente.customers_last_name}`);
                                });
                            }
                        }); */

                        // Aggiunge livello al cluster
                        markers.value.addLayer(single_marker);
                    }
                }
            });

            // Aggiugne cluster alla mappa
            map.value.addLayer(markers.value);

            // Ensure the map is centered and takes up the full width and height of its container
            map.value.invalidateSize(true);

            //Centra la mappa sui clienti appena trovati
            if (clienti.value.length > 0) {
                map.value.fitBounds(markers.value.getBounds());
            }

            setTimeout(simulateResize, 200);
        }

        const provincia = ref(null);
        const citta = ref(null);
        async function searchCustomer() {
            if (!provincia.value && !citta.value) {
                openToast("Devi inserire almeno uno dei due campi per effettuare la ricerca dei clienti", "toast_danger");
                return;
            }
            const res = await apiClienti.searchCustomers(provincia.value, citta.value);
            //console.log(res);
            clienti.value = res.data.data;
            await setMap();
        }

        //const map = ref(null);
        onMounted(async () => {
            //await getClienti();
            // Wait for next DOM update cycle
            //await nextTick();
            //await setMap();
        });

        return {
            user,
            map,
            provincia,
            citta,
            searchCustomer,
        };
    },
};
